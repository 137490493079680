var filter, plp;
$(document).ready(function(){
	if(document.querySelector('.C0026')) return false; // LGEUS-431
	if(!document.getElementById('categoryFilterForm')) return false;

	// page history
	var pageHistory = {
		usePage: false,
		cookieName: 'LG5_PageHistory',
		getHistory: function() {
			return getCookie(pageHistory.cookieName);
		},
		setHistory: function(va) {
			// view all, pageing
			if(pageHistory.usePage) {
				var cookieText;
				if(va=='view-all') {
					cookieText = location.pathname + '=viewAll';
				} else {
					cookieText = location.pathname + '=' + $(filter.el).find('input[type=hidden][name=page]').val();
				}
				setCookie(pageHistory.cookieName, cookieText);
			}
		},
		removeHistory: function() {
			// reset, filter chane, sort
			if(pageHistory.usePage) {
				if(pageHistory.getHistory()) {
					removeCookie(pageHistory.cookieName);
				}
			}
		},
		runHistory: function() {
			if(pageHistory.usePage) {
				var ctext = pageHistory.getHistory();
				if(!!ctext) {
					var cookiePath = ctext.split(','),
						cate,
						page;
					for(var key in cookiePath) {
						cate = cookiePath[key].split('=')[0];
						page = cookiePath[key].split('=')[1];
					}
					if(cate==location.pathname) {
						if(page=='viewAll') {
							setTimeout(function() {
								$('#resultProductList .result-info a.view-all').click();
							}, 500);
						} else {
							setTimeout(function() {
								if(typeof Number(page) == 'number') {
									$('#resultAppendTarget .pagination ul li').eq(page-1).find('button').click();
								}
							}, 500);
						}
					} else {
						pageHistory.removeHistory();
					}
				}
			}
		},
		init: function() {
			// Runs in C0007 component only
			if($('.C0007').length>0) {
				pageHistory.usePage = true;
			}
		}
	};
	pageHistory.init();

	// filter box function
	filter = {
		el: document.getElementById('categoryFilterForm'),
		result: document.getElementById('resultAppendTarget'),
		template: null,
		pageTemplate: null,
		banner: document.querySelector('.result-box .banner-box'),
		initial: null,
		// filter box folding script
		ui: {
			el: {
				open: document.querySelector('.filter-open-box'),
				close: document.querySelector('.filter-result'),
				fieldset: null
			},
			init: function(){
				var _this = filter.ui.el;
				_this.fieldset = filter.el.querySelectorAll('fieldset');
				
				$(_this.open).on({
					click: function(e){
						e.preventDefault();
						if(!$(filter.el).is(':visible')){ 
							$(filter.el).addClass('open'); 
							$(this).hide();
							$(_this.close).find('a').focus();
							/* 20191002 : LGEUS-12351 add */ 
							if($(".C0007").length > 0){ 
							$(".C0007 .filter-open-floating").addClass("no-floating"); 
							} 
							/* //20191002 : LGEUS-12351 add */ 
						}else{ 
							$(filter.el).hide();
						}
					}
				});
				$(_this.close).on({
					click: function(e){
						e.preventDefault();
						var state = $(filter.el).css('display');
						if($(filter.el).is(':visible')){ 
							$(filter.el).removeClass('open'); 
							$(_this.open).show();
							$(_this.open).find('a').focus();
							/* 20191002 : LGEUS-12351 add */ 
							if($(".C0007").length > 0){ 
							$(".C0007 .filter-open-floating").removeClass("no-floating"); 
							} 
							/* //20191002 : LGEUS-12351 add */ 
						}
					}
				});
				// fieldset folding script
				$(_this.fieldset).on({
					click: function(e){	
						e.preventDefault();
						var parent_box = e.delegateTarget;
						if ($(parent_box).is(".open")){
							$(parent_box).removeClass("open").addClass("close").find('.btn-list').attr('aria-expanded', false);
						} else {
							$(parent_box).removeClass("close").addClass("open").find('.btn-list').attr('aria-expanded', true);
						}
					}
				}, '.btn-list');
				/* 20191002 : LGEUS-12351 add */ 
				if($(".C0007").length > 0){ 
				var	filterLine = parseInt( $(".C0007").find(".product-list-box").offset().top );
				var filterTopLine = parseInt( $(".C0007").offset().top );
				$(window).on("resize", function(){
					filterLine = parseInt( $(".C0007").find(".product-list-box").offset().top );
					return filterLine;
				});	
				$(window).scroll(function(){
					/* 20191127 : LGEUS-12620 */ 
					filterLine = parseInt($(".C0007").find(".product-list-box").offset().top ); 
					var scrollPos =  $(window).scrollTop(); 
					if(scrollPos >= parseInt(filterLine) && $(".C0007 .filter-open-floating").length !=0 && !$(".C0007 .filter-open-floating").attr("class").match("no-floating")){ 
						$(".C0007").find('.filter-open-floating').removeClass("float-fix").addClass("float-active"); 
					}else{ 
						($(".C0007 #categoryFilterForm").attr("class").match("open"))?$(".C0007").find('.filter-open-floating').addClass("no-floating"):$(".C0007").find('.filter-open-floating').removeClass("float-active");
					} 
					/* //20191127 : LGEUS-12620 */ 
				});
				$(".C0007 .filter-open-floating .floating-filter").on({
					click:function(e){
						e.preventDefault();
						if($(this).attr("class").match("active")){
							
							$(this).removeClass("active").attr('aria-expanded', false);	/* PJTWAUS-1 : 20200121 modify */
							$(".C0007 #categoryFilterForm").removeClass("open");
							$('.C0007 .filter-open-box').css("display","block");
							//$(".C0007 .filter-open-floating").removeClass("float-fix");
							$(".C0007 .filter-open-floating").removeClass("no-floating");
							$(".C0007 .result-box .sort-box").css("display","block");
							$(".filter-box .float-filter-result").css("display","none");
							$(".filter-box .filter-result").css("display","block");
							filterTopLine = parseInt( $(".C0007").offset().top );
							//$(".C0007").addClass("float-active");
							$(window).scrollTop(filterTopLine);
						}else{
							$(this).addClass("active").attr('aria-expanded', true);	/* PJTWAUS-1 : 20200121 modify */
							$(".C0007 #categoryFilterForm").addClass("open");
							$('.C0007 .filter-open-box').css("display","none");
							//$(".C0007 .filter-open-floating").addClass("float-fix");
							$(".C0007 .filter-open-floating").addClass("no-floating");
							$(".C0007 .result-box .sort-box").css("display","none");
							$(".filter-box .float-filter-result").css("display","block");
							$(".filter-box .filter-result").css("display","none");
							filterTopLine = parseInt( $(".C0007").offset().top );
							//$(".C0007").addClass("float-active");
							$(window).scrollTop(filterTopLine);
						}
					}
				});
				/*$(".C0007 .filter-open-floating .float-sort-box .sort-select .sortBy").on({
					change:function(e){
						alert($(this).val());

					}
				});*/

				$('.C0007 .filter-open-floating .float-sort-box .sort-select .sortBy').on({
					change: function(e){
						var _this = e.currentTarget;
						var sort = _this.options[_this.selectedIndex].value;
						var sortTxt = changeTitleFormat($(this).find('option').eq(_this.selectedIndex).text());
						plp.el.form.sort.value = sort;
						plp.el.form.page.value = 1;

						pageHistory.removeHistory();

						$(plp.el.form).submit();

						$('.C0007 .filter-open-floating .floating-filter').removeClass("active");	
						$(".C0007 #categoryFilterForm").removeClass("open");
						$('.C0007 .filter-open-box').css("display","block");
						//$(".C0007 .filter-open-floating").removeClass("float-fix");
						$(".C0007 .filter-open-floating").removeClass("no-floating");
						$(".C0007 .result-box .sort-box").css("display","block");
						$(".filter-box .float-filter-result").css("display","none");
						$(".filter-box .filter-result").css("display","block");
						$('.result-box .sort-box .sort-select').find('select option[value="'+sort+'"]').prop("selected",true);
						$('.result-box .sort-box .sort-select').find('.chosen-single span').text($(this).find('option').eq(_this.selectedIndex).text());
						//filter.loadPLP(); XXX
						$('html, body').animate({
							scrollTop: $(plp.el.scrollTarget).offset().top
						}, 500);

						adobeTrackEvent('product-list-sort', {sort_option : sortTxt, page_event : {product_sort : true}});
					}
				});
				} 
				/* //20191002 : LGEUS-12351 add */ 

			}
		},
		// jquery ui slider field function
		slider: {
			el: null,
			value: null, // Array inputed in filter markup
			init: function(){
				var _this = filter.slider;
				_this.value = dragbarVal;
				_this.el = filter.el.querySelectorAll('.slider-wrap');
				
				for (var i = 0; i < _this.el.length; i++) {
					var __this = _this.el[i];
					var range = __this.querySelector('.slider-range'),
						matchArray = _this.value[__this.getAttribute('data-value')],
						max = matchArray.length-1;

					$(range).data('matchArray', matchArray)
						.slider({
							range: true,
							min: 0,
							max: max,
							values: [0, max],
							slide: _this.slide,
							change: _this.change,
						}).data("ui-slider")._slide();
					/* PJTWAUS-1 : 20200113 add */
					$(range).find('.ui-slider-handle').eq(0).attr('aria-label', range.getAttribute('data-min')).attr('role', 'slider');
					$(range).find('.ui-slider-handle').eq(1).attr('aria-label', range.getAttribute('data-max')).attr('role', 'slider');
					/*// PJTWAUS-1 : 20200113 add */
				}
			},
			slide: function( event, ui ) {
				 if(ui.values[0] == ui.values[1]) return false;

				var $wrap = $(this).parents('.slider-wrap'),
					_matchArray = $(this).data('matchArray'),
					minKey = Object.keys(_matchArray[ui.values[0]]),
					maxKey = Object.keys(_matchArray[ui.values[1]]);

				var box = {
					$min: $wrap.find('.min-box'),
					$max: $wrap.find('.max-box')
				};

				box.$min.find('span').text(minKey);
				box.$max.find('span').text(maxKey);
				/* PJTWAUS-1 : 20200113 add */
				$wrap.find('.ui-slider-handle').eq(0).attr('aria-valuetext',minKey);
				$wrap.find('.ui-slider-handle').eq(1).attr('aria-valuetext',maxKey);
				/*// PJTWAUS-1 : 20200113 add */
				/* 20191111 : LGEUS-11779 add */
				if(!box.$min.find('span').attr('data-init'))box.$min.find('span').attr('data-init', minKey);
				if(!box.$max.find('span').attr('data-init'))box.$max.find('span').attr('data-init', maxKey);
				/* //20191111 : LGEUS-11779 add */
				box.$min.find('input').val(_matchArray[ui.values[0]][minKey]);
				box.$max.find('input').val(_matchArray[ui.values[1]][maxKey]);
			},
			change: function(event, ui){
				$(this).trigger('change', [ui]);
			},
			setSlider: function(){
				var _this = filter.slider;
				if($(_this.el).is('div')) {
					for (var i = 0; i < _this.el.length; i++) {
						var __this = _this.el[i],
							__range = $(__this).find('.slider-range');

						var valueIndex = {
							min: $(__this).find('.min-box input').data('index'),
							max: $(__this).find('.max-box input').data('index')
						};
						valueIndex = {
							min: valueIndex.min ? valueIndex.min : 0,
							max: valueIndex.max ? valueIndex.max : $(__range).slider("option", "max")
						};
						$(__this).find('.slider-range')
							.slider('values', [valueIndex.min, valueIndex.max]).data("ui-slider")._slide();
					}
				}
			},
			indexOfValue: function(arr, val){
				for (var i = 0; i < arr.length; i++) {
					var _obj = arr[i];
					for(var key in _obj) {
						if(_obj[key] == val) {
							return i;
						}
					}
				}
			}
		},
		// auto field save
		cookie: {
			name: 'LG5_filter',
			cont: null,
			bake: function(param){
				var _this = filter.cookie;
				var page = location.pathname;
				// Remove unnecessary fields before baking cookies
				param = param.replace(/\&/g,'|').replace(/\=/g,':');
				
				var paramArray = param.indexOf('|') > -1 ? param.split('|') : [param];
				var i = 0;
				while ( i < paramArray.length) {
					var _param = paramArray[i];
					if(_param.split(':')[1] == '' || _param.split(':')[1] == undefined) {
						paramArray.splice(i, 1);
					}else {
						i++;
					}
				}

				if(!_this.cont) {
					_this.cont = [];
				}

				var mergeParam = paramArray.join("|"),
					ac = page+'='+mergeParam,
					idx = _this.returnData();

				if(mergeParam != "") {
					if(idx >= 0) {
						_this.cont[idx] = ac;
					}else {
						_this.cont.push(ac);
					}
				}else {
					if(idx >= 0) {
						_this.cont.splice(idx, 1);
					}
				}

				if(_this.cont.join('') == "") {
					removeCookie(_this.name, true);
				}else {
					setCookie(_this.name, _this.cont.join(','), true);
				}
			},
			get: function(){
				var _this = filter.cookie;
				var ac = getCookie(_this.name) ? getCookie(_this.name) : [];
				if(ac !== undefined) {
					ac = ac.indexOf(',') > -1 ? ac.split(',') : [ac];
					_this.cont = ac;
					var idx = _this.returnData();
					if(idx >= 0) {
						ac = ac[idx].split('=')[1];
						return ac;
					}
				}
			},
			returnData: function(){
				var _this = filter.cookie;
				var page = location.pathname;
				if(_this.cont) {
					var idx = -1;
					for (var j = 0; j < _this.cont.length; j++) {
						var c = _this.cont[j];
						if(c.indexOf(page) >= 0) {
							idx = j;
							break;
						}
					}
					return idx;
				}
			},
			reset: function(){
				var _this = filter.cookie;
				_this.bake('');
			}
		},
		init: function(){
			filter.initial = $(filter.el).serialize();
			filter.template = $('#productTemplate').clone().html();
			filter.pageTemplate = $('#paginationTemplate').clone().html();
			$('#productTemplate').remove();
			$('#paginationTemplate').remove();

			filter.ui.init(); // mobile ui events setting

			if(typeof dragbarVal != 'undefined') {
				filter.slider.init();
			}

			if(typeof filter.el.length == "object") {
				var itemLength;
				if(isMobile) {
					itemLength = filter.el.length.getAttribute('data-mobile');
				}else {
					itemLength = filter.el.length.getAttribute('data-desktop');
				}
				filter.el.length.value = itemLength;
			}

			// var param = filter.cookie.get();
			var param = filter.getParam();
			if(pageHistory.getHistory()) {
				filter.addEvent();
				pageHistory.runHistory();
			} else if(param && $('.compare-wrap').length==0){
				filter.setInput(param);
			}else {
				filter.addEvent();
			}
		},
		/*LGEUS-114 20200317*/
		getParam: function(){
			// filter.cookie.get()
			var result;
			if(location.search.length>0) {
					/* LGEUS-821 modify*/
				result = location.search.replace('?', '').replace('&&','&');
				if(result.indexOf('&')==0){
					result = result.substring(1,result.length) 
				}
					/* //LGEUS-821 modify*/				
				if(result.length>0) {
					var hasURLFilter = true;
					var arr = result.split('&');
					for(var i=0;i<arr.length;i++) {
						var name = arr[i].split('=')[0];
						if($('[name='+name+']').length == 0) {
							hasURLFilter = false;
						}
					}
					if(hasURLFilter) {
						// Process if the URL contains filter data
						result = result.replace(/\&/g,'|').replace(/\=/g,':').replace(/\?/g, '');
					} else {
						result = filter.cookie.get();
					}
				}
			}else {
				result = filter.cookie.get();
			}

			return result;
		},
		/*//LGEUS-114 20200317*/
		setInput: function(param){
			var paramArray = param.indexOf('|') > -1 ? param.split('|') : [param];
			var ignore = ['categoryId', 'subCategoryId', 'status', 'sort', 'page'];
			var form = filter.el;
			//DC-331 START
			var sliderArray = [];
			//DC-331 END

			for (var i = 0; i < paramArray.length; i++) {
				var _param = paramArray[i].split(':');
				if(ignore.indexOf(_param[0]) == -1) {
					var $input = $(form).find('input[name="'+_param[0]+'"]');

					if(!$input.get(0)) {
						// filter.reset();
						return false;
					}

					var type = $input.get(0).type;
					if(type == "checkbox") {
						var selected = $input.filter('[value="'+_param[1]+'"]').get(0);
						selected.setAttribute('checked', 'checked');
						//DC-331 Started
						var name = changeTitleFormat($(selected).closest('.option-box').find('.title strong').text());
						var val = changeTitleFormat($(selected).parent().find('.text').text());
						var relVal = ($(selected).parent().find('.text .filter-cnt').length==0)?$(selected).parent().find('.text').text():$(selected).parent().find('.text').text().replace($(selected).parent().find('.text .filter-cnt').text(),'');
						if(!val || val==""){
							// for color	
							val = changeTitleFormat($(selected).attr('title'));
							relVal = $(selected).attr('title');
						}

						var $filter_icon = "<span data-filter-value='"+$(selected).attr('id')+"'>"+relVal+"<a href='#'>remove</a></span>"; /* PJTWAUS-1 : 20200113 modify */
						if($('.C0007 .apply-filters span').length == 0){
							$('.C0007 .apply-filters a.link-text').addClass('active');
						}		
						$('.C0007 .apply-filters .link-text').before($filter_icon);

						$('.C0007 .apply-filters span[data-filter-value='+$(selected).attr('id')+'] a').one('click',function(e){
							e.preventDefault();
							$('.C0007 #categoryFilterForm input[id="'+$(this).parent().attr('data-filter-value')+'"]').click();
						});

						if(name && val) {
							adobeTrackEvent('product-list-filter', {filter_name : name+"_"+val, page_event : {filter_apply : true}});
						} else if (val) {
							adobeTrackEvent('product-list-filter', {filter_name : val, page_event : {filter_apply : true}});
						}

						//DC-331 Ended
					}else { // slider
						// set hidden input
						$input.get(0).value = _param[1];

						// set Slider
						var $slider = $input.parents('.slider-wrap');
						if($slider.length>0) {
							//DC-331 START
							var idx = filter.slider.indexOfValue(filter.slider.value[$slider.attr('data-value')], _param[1]);
							$input.data('index', idx);

							sliderArray.push(_param[0]);
							//DC-331 END
						}
					}
				}
			}
			filter.slider.setSlider();
			//DC-331 START
			if(sliderArray.length > 0){
				for(var i = 0; i < sliderArray.length; i++){
					var _param = sliderArray[i];
					var $input = $(form).find('input[name="'+_param+'"]');
					var $slider = $input.parents('.slider-wrap');

					var relVal = $($slider).siblings('.title').find('strong').text()+" : "+$($slider).children().find(".min-box span").text()+"-"+$($slider).children().find(".max-box span").text();						

					if($('.C0007 .apply-filters span[data-filter-value='+$($slider).attr('data-value')+']').length != 0){
						var _targetTxt = $('.C0007 .apply-filters span[data-filter-value='+$($slider).attr('data-value')+']');
						_targetTxt.contents().filter(function(){return this.nodeType == 3;})[0].nodeValue = relVal;
					}else{
						var $filter_icon = "<span data-filter-value='"+$($slider).attr('data-value')+"'>"+relVal+" <a href='#'>remove</a></span>";  /* PJTWAUS-1 : 20200113 modify */
						$('.C0007 .apply-filters .link-text').before($filter_icon);
						$('.C0007 .apply-filters span[data-filter-value='+$($slider).attr('data-value')+'] a').one('click',function(e){
							e.preventDefault();
							var options = $('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] .slider-range').slider('option');
							$('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] input').val('').removeData('index');
							  $('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] .slider-range').slider('values',[options.min, options.max]);
							  $('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] .min-box span').text($('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] .min-box span').attr('data-init'));
							  $('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] .max-box span').text($('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] .max-box span').attr('data-init'));
							  $(this).parent().remove();
						});	
					}

					if($('.C0007 .apply-filters span').length != 0){
						$('.C0007 .apply-filters a.link-text').addClass('active');
					}else{
						$('.C0007 .apply-filters a.link-text').removeClass('active');
					}
				}
			}
			//DC-331 END
			filter.loadPLP();
			filter.addEvent();
		},
		activateInput: function(enableList){
			if(typeof enableList === 'undefined') return false;
			var _enableList = enableList;
			var form = filter.el;

			var $labels = $(form).find('label');
			// for (var ix = 0; ix < $labels.length; ix++) {
			// 	var _$label = $labels.eq(ix);
			// 	_$label.addClass('disabled').find('input').attr('disabled','disabled');
			// }
			if(_enableList != '') {
				for (var i = 0; i < _enableList.length; i++) {
					var keyId = _enableList[i].facetValueId;
					var _$faceInput = $(form).find('input[value="'+keyId+'"]'),
					_$faceLabel = _$faceInput.parents('label');
					/*LGEUS-11780 20190805 add */
					_$faceLabel.find('.filter-cnt').text('('+_enableList[i].modelCount+')'); 
					/*LGEUS-11780 20190805 add */
					// _$faceInput.removeAttr('disabled');
					// _$faceLabel.removeClass('disabled');
					if( _enableList[i].enable.toUpperCase() == "N"){
						_$faceInput.attr('disabled', 'disabled');
						_$faceLabel.addClass('disabled');
					}else {
						_$faceInput.removeAttr('disabled');
						_$faceLabel.removeClass('disabled');
					}
				}
			}
		},
		createProductItem: function(productList){
			var html = [];
			var isB2B = $('.navigation') && $('.navigation').hasClass('b2b');

			for (var i = 0; i < productList.length; i++) {
				var p = productList[i],
					template = filter.template;
				
				var isPreorder = ['Preorder', 'Pre-Order'].includes(p.wtbExternalLinkName);
				
				template = template.replace(/\*modelId\*/g, p.modelId)
								//PJTUSGA-1,LGEUS-188
								.replace(/\*buName1\*/g, p.buName1)
								.replace(/\*productIndex\*/g, i+1)
								.replace(/\*modelName\*/g, p.modelName)
								//LGEUS-12424
								.replace(/\*replaceModelName\*/g, p.modelName.toString().replace(/ /gi, "_"))
								.replace(/\*imageAltText\*/g, p.imageAltText)
								.replace(/\*userFriendlyName\*/g, p.userFriendlyName == null ? '' : p.userFriendlyName)
								.replace(/\*salesModelCode\*/g, p.salesModelCode)
								.replace(/\*modelUrlPath\*/g, p.modelUrlPath)
								.replace(/\*mediumImageAddr\*/g, p.mediumImageAddr)
								.replace(/\*smallImageAddr\*/g, p.smallImageAddr)
								.replace(/\*productTag1\*/g, p.productTag1)
								.replace(/\*productTag2\*/g, p.productTag2)
								/* LGEUS-12198 : 20191024 Removed */
								.replace(/\*addToCartFlag\*/g, p.addToCartFlag)
								.replace(/\*obsInventoryFlag\*/g, p.obsInventoryFlag)
								.replace(/\*inquiryToBuyUrl\*/g, p.inquiryToBuyUrl)
								.replace(/\*findTheDealerUrl\*/g, p.findTheDealerUrl)
								.replace(/\*inquiryToBuyFlag\*/g, p.inquiryToBuyFlag)
								.replace(/\*promotionText\*/g, p.promotionText ? p.promotionText : "")
								.replace(/\*rPrice\*/g, p.rPrice ? p.rPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'null')
								.replace(/\*rPromoPrice\*/g, p.rPromoPrice ? p.rPromoPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'null')
								.replace(/\*rPriceCent\*/g, p.rPriceCent)
								.replace(/\*rPromoPriceCent\*/g, p.rPromoPriceCent)
								/* LGEUS-12083 : 20190826 add */
								.replace(/\*rDiscountedPrice\*/g, p.rDiscountedPrice ? p.rDiscountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'null')
								.replace(/\*rDiscountedPriceCent\*/g, p.rDiscountedPriceCent)
								/* //LGEUS-12083 : 20190826 add */
								.replace(/\*siblingType\*/g, p.siblingType)
								.replace(/\*discountedRate\*/g, p.discountedRate)
								.replace(/\*retailerPricingText\*/g, p.retailerPricingText)
								.replace(/\*obsCondition\*/g, p.obsCondition);		// DC-680 & DC-904 KyuhaHwang 20210603 modify

				if (isPreorder) {
					template = template.replace(/where-to-buy/g, 'preorder')
									.replace(/btn-outline-secondary/g, 'btn-primary');
				}
				
				/* LGEUS-12198 : 20191024 add */
				if (p.wtbExternalLinkUseFlag=='Y') {
					template = template.replace(/\*whereToBuyUrl\*/g, p.wtbExternalLinkUrl)
							   .replace(/\*whereToBuyTarget\*/g, p.wtbExternalLinkSelfFlag=='N' ? "_blank" : "_self")
							   .replace(/\*whereToBuyTitle\*/g, p.wtbExternalLinkSelfFlag=='N' ? $(template).find('a.where-to-buy').attr('data-link-text') : "")
							   .replace(/\*whereToBuyName\*/g, p.wtbExternalLinkName);
				} else {
					template = template.replace(/\*whereToBuyUrl\*/g, p.whereToBuyUrl)
							   .replace(/\*whereToBuyTarget\*/g, "_self")
							   .replace(/\*whereToBuyTitle\*/g, "")
							   .replace(/\*whereToBuyName\*/g, $(template).find('a.where-to-buy').attr('data-link-text'));
				}
				/* //LGEUS-12198 : 20191024 add */
				
				var $template = $(template).filter(function(index) {
					return $(this)[0].nodeName !== '#text';
				})
				var	$keyBlocks = $template.find('*[data-key]')
				var $loopBlocks = $template.find('*[data-loop]');

				for (var i1 = 0; i1 < $keyBlocks.length; i1++) {
					/* LGEUS-12198 : 20191024 add */
					var $currentKeyBlock = $keyBlocks.eq(i1);
					var key = $currentKeyBlock.get(0).getAttribute('data-key');
					var dataFlag = $currentKeyBlock.get(0).getAttribute('data-flag');
					if (p.wtbExternalLinkUseFlag=='Y' && key=="whereToBuyFlag") { 
						key = "wtbExternalLinkUseFlag"; 
					}
					var val = p[key];
					
					/* //LGEUS-12198 : 20191024 add */
					if(!val || (val == null || val == "N")) {
						if ((key === 'addToCartFlag' && dataFlag === 'N') || (key === 'inquiryToBuyFlag' && dataFlag === 'N')) {
							if (key === 'addToCartFlag') {
								$currentKeyBlock.removeClass('active');
							} else {
								$currentKeyBlock.remove();
							}
						} else if($currentKeyBlock.is('.btn')) {
							$currentKeyBlock.removeClass('active');
						} else {
							$currentKeyBlock.remove();
						}
					} else {
						if($currentKeyBlock.is('.btn')) {
							$currentKeyBlock.addClass('active');
						}
					}
				}

				// sibling target check
				if(p.target && p.target.toUpperCase() == "SELF") {
					$template.find('.item.js-model').addClass('self');
				}else {
					$template.find('.item.js-model').removeClass('self');
				}

				// price setting
				var $prices = $template.find('.price-area');
				if(p.modelStatusCode=='DISCONTINUED') {
					$prices.filter('.rPrice').html("");
					$prices.filter('.rPromoPrice').remove();
					$prices.filter('.retailer').remove();
					// DC-680 20210526 modify
				} else if(p.obsCondition == "Y") {
					if(p.rPromoPrice && p.rPromoPrice != null) {
						$prices.filter('.rPrice').remove();
						$prices.filter('.retailer').remove();
					} else if(p.rPrice && p.rPrice != null){
						$prices.filter('.rPromoPrice').remove();
						$prices.filter('.retailer').remove();
					} else {
						$prices.filter('.rPrice').html("");
						$prices.filter('.rPromoPrice').remove();
						$prices.filter('.retailer').remove();
					}
				} else {
					if(p.retailerPricingFlag=="Y") {
						$prices.filter('.rPrice').remove();
						$prices.filter('.rPromoPrice').remove();
					}else {
						if(p.rPromoPrice && p.rPromoPrice != null) {
							$prices.filter('.rPrice').remove();
							$prices.filter('.retailer').remove();
						} else if(p.rPrice && p.rPrice != null){
							$prices.filter('.rPromoPrice').remove();
							$prices.filter('.retailer').remove();
						} else {
							$prices.filter('.rPrice').html("");
							$prices.filter('.rPromoPrice').remove();
							$prices.filter('.retailer').remove();
						}
					}
					// DC-680 20210526 modify
				}

				// siblingModels
				var $sibling = $template.find('.model-group .inner');
				var siblingTypeclass;
				if(p.siblingType && p.siblingType != null) {
					siblingTypeclass = (p.siblingType.toLowerCase() == "color") ? "color" : "size";
				}else {
					siblingTypeclass = null;
				}
				
				var havSiblingModels = p.siblingModels && p.siblingModels.length > 0;
				if((havSiblingModels && siblingTypeclass != null) && $sibling.get(0)) {
					var siblingItem = $template.find('.model-group .'+siblingTypeclass).clone().html(),
						siblingMarkup = [];

					for (var _j = 0; _j < p.siblingModels.length; _j++) {
						var sbModel = p.siblingModels[_j];
						var item = siblingItem.replace(/\*siblingCode\*/g, sbModel.siblingCode)
											.replace(/\*siblingValue\*/g, sbModel.siblingValue)
											.replace(/\*subModelId\*/g, sbModel.modelId);
						if(sbModel.modelId != p.modelId) {
							item = item.replace('active', '');
							item = item.replace('aria-checked="true"', 'aria-checked="false"'); //PJTWAUS-1 : 20200113 add
						}
						siblingMarkup += item;
					}
					$sibling.append(siblingMarkup);
					/* PJTWAUS-1 : 20200121 add */
					var ariaModelName = p.userFriendlyName;
					if(ariaModelName == ''){
						ariaModelName = p.modelName;
					}					
					$sibling.attr('aria-label', ariaModelName + ' ' +siblingTypeclass + ' group');
					/*// PJTWAUS-1 : 20200121 add */
				}

				// rolling image
				if(p.modelRollingImgList && p.modelRollingImgList != null) {
					$template.find('.visual img.pc').addClass('js-thumbnail-loop').attr('data-img-list', p.modelRollingImgList);
				}

				$template.find('template').remove();
				if(i == 0){ // accessiblity focus item
					$template.addClass('first-item');
				}
				
				//template = isB2B ? $template.get(i).outerHTML : $template.get(0).outerHTML;
				var indexNum = 0;
				if(i == $template.length) {
					indexNum = i-1;
				} else if(i > $template.length) {
					indexNum = 0;
				} else {
					indexNum = i;
				} 
				
				template = isB2B ? $template.get(indexNum).outerHTML : $template.get(0).outerHTML;
				
				if(template === undefined){
					template = $template.get(0).outerHTML;
					html += template;				
				} else {
					html += template;
				}
			}

			return html;
		},
		loadPLP: function(e){
			var form = filter.el;
			var isExpander = $(form).data('focus') == true;
			var url = form.action;

			if(e && e.type == 'submit') e.preventDefault(); // if page change
			else if(form.page) form.page.value = 1; // if filter option change

			var param = xssfilter($(form).serialize()),
				//cookieParam = $(form).find('fieldset').serialize(); // IE doesn't support this.
				cookieParam = $(form).serialize();

			ajax.call(url, param, 'json', function(d){
				var data;
				if(d && d.data) data = d.data instanceof Array ? d.data[0] : d.data;
				if(d.status == "success" && data) {

					// reset no-contents area
					if($(filter.result.querySelector('.no-content')).is(':visible')) {
						$(filter.result).children().removeAttr('style');
					}

					data.productList = data.productList && data.productList != null ? data.productList : []; 
					data.multiCategorys = data.multiCategorys && data.multiCategorys != null ? data.multiCategorys : []; 
					/* LGEUS-11775 : 20190807 add, LGEUS-11780 20190812 add */ 
					if(data.promotionTotalCount != null && filter.el.querySelector('input[type=checkbox][name=promotionsOnly]') != null  && data.promotionTotalCount == 0){
						var promotionsOnlySelector = filter.el.querySelector('input[type=checkbox][name=promotionsOnly]');
						promotionsOnlySelector.setAttribute("disabled",'disabled');
						promotionsOnlySelector.parentNode.classList.add("disabled");
						$(promotionsOnlySelector).siblings('.filter-cnt').text('('+data.promotionTotalCount+')');
					}else if(data.promotionTotalCount != null && filter.el.querySelector('input[type=checkbox][name=promotionsOnly]') != null  && data.promotionTotalCount > 0){
						var promotionsOnlySelector = filter.el.querySelector('input[type=checkbox][name=promotionsOnly]');
						promotionsOnlySelector.removeAttribute("disabled");
						promotionsOnlySelector.parentNode.classList.remove("disabled");
						$(promotionsOnlySelector).siblings('.filter-cnt').text('('+data.promotionTotalCount+')');
					}
					
					if(data.obsTotalCount != null && filter.el.querySelector('input[type=checkbox][name=obsOnly]') != null  && data.obsTotalCount == 0){
						var obsOnlySelector = filter.el.querySelector('input[type=checkbox][name=obsOnly]');
						obsOnlySelector.setAttribute("disabled",'disabled');
						obsOnlySelector.parentNode.classList.add("disabled");
						$(obsOnlySelector).siblings('.filter-cnt').text('('+data.obsTotalCount+')');
					}else if(data.obsTotalCount != null && filter.el.querySelector('input[type=checkbox][name=obsOnly]') != null  && data.obsTotalCount > 0){
						var obsOnlySelector = filter.el.querySelector('input[type=checkbox][name=obsOnly]');
						obsOnlySelector.removeAttribute("disabled");
						obsOnlySelector.parentNode.classList.remove("disabled");
						$(obsOnlySelector).siblings('.filter-cnt').text('('+data.obsTotalCount+')');
					}
					
					// LGEUS-474
					if(data.thinqTotalCount != null && filter.el.querySelector('input[type=checkbox][name=thinQ]') != null  && data.thinqTotalCount == 0){
						var thinQSelector = filter.el.querySelector('input[type=checkbox][name=thinQ]');
						thinQSelector.setAttribute("disabled",'disabled');
						thinQSelector.parentNode.classList.add("disabled");
						$(thinQSelector).siblings('.filter-cnt').text('('+data.thinqTotalCount+')');
					}else if(data.thinqTotalCount != null && filter.el.querySelector('input[type=checkbox][name=thinQ]') != null  && data.thinqTotalCount > 0){
						var thinQSelector = filter.el.querySelector('input[type=checkbox][name=thinQ]');
						thinQSelector.removeAttribute("disabled");
						thinQSelector.parentNode.classList.remove("disabled");
						$(thinQSelector).siblings('.filter-cnt').text('('+data.thinqTotalCount+')');
					}
					// LGEUS-474 End
					
					if(data.bundlesTotalCount != null && filter.el.querySelector('input[type=checkbox][name=bundlesOnly]') != null  && data.bundlesTotalCount == 0){
						var bundlesOnlySelector = filter.el.querySelector('input[type=checkbox][name=bundlesOnly]');
						bundlesOnlySelector.setAttribute("disabled",'disabled');
						bundlesOnlySelector.parentNode.classList.add("disabled");
						$(bundlesOnlySelector).siblings('.filter-cnt').text('('+data.bundlesTotalCount+')');
					}else if(data.bundlesTotalCount != null && filter.el.querySelector('input[type=checkbox][name=bundlesOnly]') != null  && data.bundlesTotalCount > 0){
						var bundlesOnlySelector = filter.el.querySelector('input[type=checkbox][name=bundlesOnly]');
						bundlesOnlySelector.removeAttribute("disabled");
						bundlesOnlySelector.parentNode.classList.remove("disabled");
						$(bundlesOnlySelector).siblings('.filter-cnt').text('('+data.bundlesTotalCount+')');
					}
					/* LGEUS-11775 : 20190807 add, LGEUS-11780 20190812 add */ 
					//if(data.productList.length > 0 || data.multiCategorys.length == 1) {
					if($(filter.result.querySelector('.promotion-wrapper')).length==0 && (data.productList.length > 0 || data.multiCategorys.length == 1)) {
						// normal product list & single promotion list
						var data =  d.data instanceof Array ? d.data[0] : d.data,
							html;

						// if single promotion type
						if(data.multiCategorys && data.multiCategorys.length == 1) {
							var singleTotalCount = data.totalCount;
							var singleCate = data.multiCategorys,
								filterEnableList = data.filterEnableList;
							data = singleCate instanceof Array ? singleCate[0] : singleCate;
							data.filterEnableList = filterEnableList;
						}

						// make markup
						html = filter.createProductItem(data.productList);

						var pageInfo = data.pageInfo;
						if(isExpander) { // mobile append list
							$(filter.result.querySelector('.pagination')).hide();

							$(filter.result.querySelector('.list-box')).append(html);
							bindImgError();
							var pageVisible = pageInfo && pageInfo.view == "Y";
							if(pageVisible && pageInfo.rightPage == true) {
								filter.result.querySelector('.expander button').value = pageInfo.page+1;
								$(filter.result.querySelector('.expander')).show();
							}else {
								$(filter.result.querySelector('.expander')).hide();
							}
						}else {
							$(filter.result.querySelector('.expander')).hide();

							$(filter.result.querySelector('.list-box')).html(html);
							bindImgError();
							if(data.totalCount) {
								$('.result-number').text(data.totalCount);
							}else if(singleTotalCount){
								$('.result-number').text(singleTotalCount);
							}
							/* 20191111 : LGEUS-11779 add */
							if($('#categoryFilterForm input[type=checkbox]:checked').length == 0 && $('#categoryFilterForm .slider-wrap input[value!=""]').length == 0){
								$('.result-info .filterTotal').hide();
								$('.result-info .total').show();
							}else{
								$('.result-info .filterTotal').show();
								$('.result-info .total').hide();
							}
							/* //20191111 : LGEUS-11779 add */
							// setting pagination
							if(pageInfo && pageInfo.view == "Y") {
								if(filter.result.querySelector('.pagination')){
									var pageMarkup = [];
									for (var pageIdx = pageInfo.loopStart; pageIdx <= pageInfo.loopEnd; pageIdx++) {
										var _pageItem = filter.pageTemplate;
										_pageItem = $(_pageItem).get(0),
										_pageButton = _pageItem.querySelector('button');
										_pageButton.value = pageIdx;
										_pageButton.innerHTML = pageIdx;
										if(pageIdx.toString() == pageInfo.page) {
											_pageButton.setAttribute('class', 'active');
											//LGEGMO-5525 add
											_pageButton.setAttribute('aria-selected', 'true');

										}
										if(filter.result.querySelector('.pagination').getAttribute('data-aria-pattern')) {
											var _ariaText = filter.result.querySelector('.pagination').getAttribute('data-aria-pattern').replace(/\#/g, pageIdx);
											_pageButton.setAttribute('aria-label', _ariaText);
										}
										pageMarkup += _pageItem.outerHTML;
									}
									$(filter.result.querySelector('.pagination ul')).html(pageMarkup);

									filter.result.querySelector('.pagination .prev').disabled = !pageInfo.leftPage;
									filter.result.querySelector('.pagination .next').disabled = !pageInfo.rightPage;
									filter.result.querySelector('.pagination .prev').value = pageInfo.page-1;
									filter.result.querySelector('.pagination .next').value = pageInfo.page+1;
									$(filter.result.querySelector('.pagination')).show();
								}else if(filter.result.querySelectorAll('.btn-area').length > 0){
									filter.result.querySelector('.btn-area .prev').disabled = !pageInfo.leftPage;
									filter.result.querySelector('.btn-area .next').disabled = !pageInfo.rightPage;
									$(filter.result.querySelector('.btn-area')).show();
								}
							}else {
								$(filter.result.querySelector('.pagination')).hide();
								// .btn-area should not be hidden in compare product page.
								if($('.compare-wrap').length==0) {
									// for plp page
									$(filter.result.querySelector('.btn-area')).hide();
								} else {
									// for compare product
									filter.result.querySelector('.btn-area .prev').disabled = !pageInfo.leftPage;
									filter.result.querySelector('.btn-area .next').disabled = !pageInfo.rightPage;
									$(filter.result.querySelector('.btn-area')).show();
								}
							}

							filter.activateInput(data.filterEnableList);
							plp.mediaBranch(window.matchMedia('(max-width: 1069px)'));
						}
						
						$(form).data('focus', false);
						$(filter.el).trigger('appended');

						if(e && e.type == 'submit') {
							// if event type is page change
							var _focusItem = filter.result.querySelector(".first-item");
							//$(_focusItem).find("a, button").eq(0).focus(); PJTWAUS-1 : 20200107 remove
							$(_focusItem).removeClass("first-item");
						}

						if(typeof runEcorebates !== "undefined") {
							if($.isFunction(runEcorebates)) runEcorebates();
						}
						
					}else if(data.multiCategorys.length >= 1) {
						// multi promotion category
						$(filter.result.querySelector('.promotion-wrapper')).html('');
						for (var i = 0; i < data.multiCategorys.length; i++) {
							var promoTemplate = $('#promotionTemplate').clone().html(),
								p = data.multiCategorys[i];

							promoTemplate = promoTemplate.replace(/\*categoryName\*/g, p.categoryName)
												.replace(/\*categoryId\*/g, p.categoryId)
												.replace(/\*viewAllAjaxUrl\*/g, p.viewAllAjaxUrl)
												.replace(/\*productList\*/g, filter.createProductItem(p.productList));

							var $promoTemplate = $(promoTemplate);

							// pagination (view all)
							var pageInfo = p.pageInfo;
							if(data.totalCount) {
								$('.result-number').text(data.totalCount);
							}
							if(!p.viewAllAjaxUrl || p.viewAllAjaxUrl == null) {
								$promoTemplate.find('.list-more').removeAttr('data-url');
							}

							$(filter.result.querySelector('.promotion-wrapper')).append($promoTemplate.get(0).outerHTML);
							bindImgError();
							
							filter.activateInput(data.filterEnableList);
							plp.mediaBranch(window.matchMedia('(max-width: 1069px)'));
						}
						
						if(typeof runEcorebates !== "undefined") {
							if($.isFunction(runEcorebates)) runEcorebates();
						}
					}else {
						// empty data;
						$(filter.result.querySelector('.no-content')).show()
							.siblings().hide();
						$('.result-number').text('0');
						//LGEUS-11780 20190812 add
						filter.activateInput(data.filterEnableList);
						//LGEUS-11780 20190812 add
					}

					if(thumbnailLoop) $(filter.result).trigger('thumbnailCarousel');
				}

				if(!e || e.type != 'submit') {
					// if filter option change
					filter.cookie.bake(cookieParam);
				}

				// clear hidden input
				//if(form.viewAll) {
				//	form.viewAll.value = "";
				//}
				if(form.length) {
					form.length.disabled = false;
				}

				$('body').trigger('initialized-plp');
			}, null, 'body');

			
			if(filter.initial == param) {
				// if the initial Form data is the same as this Form data
				$(filter.banner).show();
			}else {
				$(filter.banner).hide();
			}
		},
		// C0026 multi category View All (.list-more)
		viewAllPromotionPLP: function(e, isWholeBtn){
			var $closest = $(e.currentTarget).closest('.promotion-box'),
				$listMore = $(e.currentTarget).closest('.list-more'),
				url = $listMore.get(0).getAttribute('data-url'),
				dataParam = $listMore.data();
				// dataParam = $.param($(filter.el).serializeArray()) + $.param($listMore.data());

			delete dataParam.url;

			var newParam = $(filter.el).serialize().replace(/\&filterCategoryId=[A-Z]+[0-9]+/g, '') + '&filterCategoryId='+$listMore.data('category-id');

			//dataParam = $.param(dataParam) + "&" + $(filter.el).serialize();
			dataParam = $.param(dataParam) + "&" + newParam;

			// find auto focusing item
			var $focusItem = $closest.find(".list-box li:visible:last").next();
			if(url && url != null) {
				ajax.call(url, xssfilter(dataParam), 'json', function(d){
					var data;

					if(d && d.data) data = d.data instanceof Array ? d.data[0] : d.data;

					// json에서 multiCategorys: [] 로 출력되는 경우 오류 방지
					data.productList = !data.productList ? ((!data.multiCategorys[0]) ? data.multiCategorys : data.multiCategorys[0].productList) : data.productList;
					if(data.productList) {
						var data =  d.data instanceof Array ? d.data[0] : d.data,
							html = filter.createProductItem(data.productList);

						// append item markup
						$closest.find('.list-box').append(html)
							.find(".first-item").removeClass("first-item");
						bindImgError();

						// open promotion box
						$closest.addClass('open');

						// focusing item
						if(!isWholeBtn) { // if top of view all button
							$focusItem.find("a, button").eq(0).focus();
						}else {
							$(filter.result).find("a, button").eq(0).focus();
						}

						if(thumbnailLoop) $(filter.result).trigger('thumbnailCarousel');
					}
				}, null, 'body');

			}else {
				$closest.addClass('open');

				// focusing item
				if(!isWholeBtn) {
					$focusItem.find("a, button").eq(0).focus();
				}else {
					$(filter.result).find("a, button").eq(0).focus();
				}
			}
		},
		reset: function(e){
			var form = filter.el;
			// if(window.history.pushState) {
			// 	window.history.pushState({},'','?');
			// }else {
			// 	location.search = '';
			// }
			
			filter.removeEvent();
			filter.cookie.reset();

			$(form).find('input:checked').removeAttr('checked');
			$(form).find('.slider-wrap input').val('').removeData('index');
			filter.slider.setSlider();

			pageHistory.removeHistory();
			/* 20191111 : LGEUS-11779 add */
			$('.C0007 .apply-filters span').remove();
			$('.C0007 .apply-filters a.link-text').removeClass('active');
			/* //20191111 : LGEUS-11779 add */

			setTimeout(function() {
				filter.loadPLP();
				filter.addEvent();
			}, 100);
		},
		removeEvent: function(){
			$(filter.el).off();
		},
		addEvent: function(){
			$(filter.el).on({
				submit: filter.loadPLP
			});
			$(filter.el).on({
				change: function(event, ui) {
					if(ui && ui.handle) {
						/* 20191111 : LGEUS-11779 add */
						var min= $(this).siblings().find('.min-box input').get(0),
							max = $(this).siblings().find('.max-box input').get(0);
						var inputArray = [min, max],
							idx = ui.handleIndex;
						if(inputArray[idx].value && inputArray[idx].value != inputArray[idx].getAttribute('data-old')) {
							var v = (idx==0) ? ($(this).siblings().find('.min-box span').text().replace(/[^0-9.]/g,'')) : ($(this).siblings().find('.max-box span').text().replace(/[^0-9.]/g,''));
							adobeTrackEvent('product-list-filter', {filter_name : inputArray[idx].getAttribute('name')+"_"+v, page_event : {filter_apply : true}});
						}
						inputArray[idx].setAttribute('data-old', inputArray[idx].value);
						if($(this).siblings().find('.min-box input').val()=="" && $(this).siblings().find('.max-box input').val()==""){
							$('.C0007 .apply-filters span[data-filter-value='+$(this).parent().attr('data-value')+']').remove();
						}else{
							var relVal = $(this).parent().siblings('.title').find('strong').text()+" : "+$(this).siblings().find(".min-box span").text()+"-"+$(this).siblings().find(".max-box span").text();						
							if($('.C0007 .apply-filters span[data-filter-value='+$(this).parent().attr('data-value')+']').length != 0){
								var _targetTxt = $('.C0007 .apply-filters span[data-filter-value='+$(this).parent().attr('data-value')+']');
								_targetTxt.contents().filter(function(){return this.nodeType == 3;})[0].nodeValue = relVal;
							}else{
								var $filter_icon = "<span data-filter-value='"+$(this).parent().attr('data-value')+"'>"+relVal+" <a href='#'>remove</a></span>";  /* PJTWAUS-1 : 20200113 modify */
								$('.C0007 .apply-filters .link-text').before($filter_icon);		
								$('.C0007 .apply-filters span[data-filter-value='+$(this).parent().attr('data-value')+'] a').one('click',function(e){
									e.preventDefault();
									var options = $('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] .slider-range').slider('option');
									$('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] input').val('').removeData('index');
  									$('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] .slider-range').slider('values',[options.min, options.max]);
  									$('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] .min-box span').text($('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] .min-box span').attr('data-init'));
  									$('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] .max-box span').text($('.C0007 .slider-wrap[data-value="'+$(this).parent().attr('data-filter-value')+'"] .max-box span').attr('data-init'));
  									$(this).parent().remove();
								});						
							}
						}
						if($('.C0007 .apply-filters span').length != 0){
							$('.C0007 .apply-filters a.link-text').addClass('active');
						}else{
							$('.C0007 .apply-filters a.link-text').removeClass('active');
						}
					} else {
						var name = changeTitleFormat($(this).closest('.option-box').find('.title strong').text());
						var val = changeTitleFormat($(this).parent().find('.text').text());
						var relVal = ($(this).parent().find('.text .filter-cnt').length==0)?$(this).parent().find('.text').text():$(this).parent().find('.text').text().replace($(this).parent().find('.text .filter-cnt').text(),'');
						if(!val || val==""){
							// for color	
							val = changeTitleFormat($(this).attr('title'));
							relVal = $(this).attr('title');
						}		
						if($(this).prop('checked')) {
							//DC-331 Modified
							var $filter_icon = "<span data-filter-value='"+$(this).attr('id') +"'>"+relVal+"<a href='#'>remove</a></span>"; /* PJTWAUS-1 : 20200113 modify */
							//DC-331 END
							if($('.C0007 .apply-filters span').length == 0){
								$('.C0007 .apply-filters a.link-text').addClass('active');
							}		
							$('.C0007 .apply-filters .link-text').before($filter_icon);

							//DC-331 Modified
							$('.C0007 .apply-filters span[data-filter-value='+$(this).attr('id')+'] a').one('click',function(e){
								e.preventDefault();
								$('.C0007 #categoryFilterForm input[id="'+$(this).parent().attr('data-filter-value')+'"]').click();
							});
							//DC-331 END
							if(name && val) {
								adobeTrackEvent('product-list-filter', {filter_name : name+"_"+val, page_event : {filter_apply : true}});
							} else if (val) {
								adobeTrackEvent('product-list-filter', {filter_name : val, page_event : {filter_apply : true}});
							}
						}else{
							$('.C0007 .apply-filters span[data-filter-value='+$(this).attr('id')+']').remove(); //DC-331 Modified
							if($('.C0007 .apply-filters span').length == 0){
								$('.C0007 .apply-filters a.link-text').removeClass('active');
							}
						}
						
					}
					if(plp.el.form.viewAll) plp.el.form.viewAll.value = '';
					pageHistory.removeHistory();
					filter.loadPLP();
				}
			}, 'input, .slider-range');
			$(filter.el).on({
				reset: filter.reset
			});
			$('.C0007 .apply-filters .link-text').on('click',function(e){
				e.preventDefault();
				$(filter.el).find('.etc-box button[type="reset"]').trigger('click');
			});
			/* //20191111 : LGEUS-11779 add */
		}
	};

	// PLP pagination & sorting function
	plp = {
		el: {
			list: document.getElementById('resultProductList'),
			scrollTarget: document.getElementById('resultAppendTarget'),
			form: document.getElementById('categoryFilterForm')
		},
		init: function(){
			var _this = plp;
			plp.addEvent();
			_this.mediaBranch(window.matchMedia('(max-width: 1069px)'));

			if($('.C0007').length > 0) {  
				$('.C0007').find('.chosen-single .icon svg').attr('role', 'img');
			}
		},
		mediaBranch: function(e, rebuild) {
			var _this = plp,
				l = _this.el.list.querySelectorAll('.promotion-box'),
				leng = filter.el.querySelectorAll('.option-box').length;
			
			leng = leng > 0 ? 3 : 4;

			if (e.matches) { // mobile;
				for (var i = 0; i < l.length; i++) {
					var _l = l[i];
					if(2 >= _l.querySelectorAll('.list-box li').length) {
						$(_l).addClass('js-open');
					}else {
						$(_l).removeClass('js-open');
					}
				}
			} else { 
				for (var j = 0; j < l.length; j++) {
					var _lj = l[j];
					if(leng >= _lj.querySelectorAll('.list-box li').length) {
						$(_lj).addClass('js-open');
					}else {
						$(_lj).removeClass('js-open');
					}
				}
			}
		},
		addEvent: function(){
			var _this = this;
			$(plp.el.list).on({
				click: function(e){
					e.preventDefault();
					var page;
					if($(e.currentTarget).closest('.expander')[0]) { // lead more button
						page = e.currentTarget.value;
						$(plp.el.form).data('focus', true);
					}else { // general page button
						page = $(e.currentTarget).is('a') ? e.currentTarget.getAttribute('href') : e.currentTarget.value;

						var pagePosition = "";
						if($(e.currentTarget).is(".prev")) {
							pagePosition = "L";
						}else if($(e.currentTarget).is(".next")){
							pagePosition = "R";
						}
						plp.el.form.pagePosition.value = pagePosition;

						$('html, body').animate({
							scrollTop: $(plp.el.list).offset().top
						}, 500);
						$(plp.el.form).data('focus', false);
					}

					// multi-promotion view all button (multi-promotion page does not have a pagination)
					if($('.promotion-wrapper .list-more').length > 0) {
						// trigger all of view-all button click event
						$('.promotion-wrapper .list-more a:visible').trigger('click', [true]);
					// general view all button
					}else {
						if($(e.currentTarget).is('.view-all')) {
							if(plp.el.form.viewAll) plp.el.form.viewAll.value = "Y";
							plp.el.form.length.disabled = true;
							plp.el.form.page.value = 'viewAll';
						}else {
							//if(plp.el.form.viewAll) plp.el.form.viewAll.value = "N";
							plp.el.form.length.disabled = false;
							plp.el.form.page.value = page;
						}
						$(plp.el.form).submit();
						//filter.loadPLP(); XXX
					}

					if($(e.currentTarget).is('.view-all')) {
						pageHistory.setHistory('view-all');
					} else if($(e.currentTarget).is('.pagination a') || $(e.currentTarget).is('.pagination button')) {
						pageHistory.setHistory();
					}

				}
			}, '.pagination a, .pagination button, a.view-all, .expander button');
			$(plp.el.list).on({
				change: function(e){
					var _this = e.currentTarget;
					var sort = _this.options[_this.selectedIndex].value;
					var sortTxt = changeTitleFormat($(this).find('option').eq(_this.selectedIndex).text());
					plp.el.form.sort.value = sort;
					plp.el.form.page.value = 1;

					pageHistory.removeHistory();

					$(plp.el.form).submit();
					//filter.loadPLP(); XXX
					/* PJTWAUS-1 : 20200107 remove */
					/*$('html, body').animate({
						scrollTop: $(plp.el.scrollTarget).offset().top
					}, 500);*/
					/*// PJTWAUS-1 : 20200107 remove */
					/* 20191002 : LGEUS-12351 add  */ 
					if($(".C0007").length > 0){  
					$('.C0007 .filter-open-floating .float-sort-box .sort-select').find('select option[value="'+sort+'"]').prop("selected",true);  
					}  
					/* //20191002 : LGEUS-12351 add */  
					adobeTrackEvent('product-list-sort', {sort_option : sortTxt, page_event : {product_sort : true}});
				}
			}, '#sortBy');

			// only multi promotion page
			$(plp.el.list).on({
				// click: function(e){
				// 	var closest = $(e.currentTarget).closest('.promotion-box');
				// 	closest.addClass('open');
				// }
				click: filter.viewAllPromotionPLP
			}, '.promotion-box .list-more a');

			window.matchMedia('(max-width: 1069px)').addListener(_this.mediaBranch);
		}
	};

	filter.init();
	plp.init();
});
